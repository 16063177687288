import { Component, Output, Input, EventEmitter } from '@angular/core';
import { Airport } from 'src/app/shared/models/airport';

@Component({
  selector: 'app-home-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})

export class DropdownComponent {
  @Output() valueChanged = new EventEmitter<string>();
  @Input() airports!: Airport[]

  onValueChange(value: string) {
    this.valueChanged.emit(value);
  }
}
