import { Injectable } from '@angular/core';
import { SpinnerDialogComponent } from '../shared/components/spinner-dialog/spinner-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SpinnerDialogService {

  private _isLoading = false;

  spinnerDialog!: MatDialogRef<SpinnerDialogComponent>;

  constructor(
    private matDialog: MatDialog
  ) { }

  startSpinner(message = '') {
    this.closeSpinner();
    this.spinnerDialog = this.matDialog.open(SpinnerDialogComponent, {
      data: message,
      backdropClass: 'spinner-bd',
      disableClose: true,
      autoFocus: false
    });
    this._isLoading = true;
  }

  closeSpinner() {
    if (this.spinnerDialog) {
      this.spinnerDialog.close();
      this._isLoading = false;
    }
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
}
