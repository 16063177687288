import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { PersonalDetailsFormStateService } from 'src/app/services/personal-details-form-state.service';

@Component({
  selector: 'app-flight-passengers',
  templateUrl: './flight-passengers.component.html',
  styleUrls: ['./flight-passengers.component.scss']
})
export class FlightPassengersComponent {
  @Output() addPassengers = new EventEmitter<void>();
  @Output() removePassengers = new EventEmitter<void>();
  @Output() closeAddRemovePassengers = new EventEmitter<void>();
  isOpen = false;
  constructor(private _personalDetailStateService: PersonalDetailsFormStateService) {

  }

  addPassenger() {
    if (this.totalPassengers < 6) {
      this.addPassengers.emit();
    }
  }

  removePassenger() {
    if (this.totalPassengers > 1) {
      this.removePassengers.emit();
    }
  }
  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
    this.closeAddRemovePassengers.emit();
    console.log("entro al cerrar");
  }
  get passengersInfo(): UntypedFormArray {
    return this._personalDetailStateService.passengersInfo as UntypedFormArray;
  }

  get totalPassengers(): number {
    return this.passengersInfo.value.length;
  }
}
