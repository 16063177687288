<div class="container-passengers">
    <div>
        <label style="font-weight: 500;">Passenger(s)</label>
    </div>
    <div>
        <button class="passenger-btn" mat-button (click)="open()" click-stop-propagation cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"><mat-icon class="passenger-icon">person</mat-icon> {{totalPassengers}}
            Adult</button>
        <ng-template cdkConnectedOverlay (overlayOutsideClick)="close()" click-stop-propagation
            [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
            <ul class="example-list">
                <li>
                    <div style="align-items: center;" class="row p-2">
                        <div class="col-6"><label style="margin-bottom: 0;">Adult</label></div>
                        <div class="col-6 flight-passengers-control">
                            <button (click)="removePassenger()" style="z-index: 100;" class="btn-passengers"> -
                            </button>
                            <span class="text-center"> {{totalPassengers}} </span>
                            <button class="btn-passengers" (click)="addPassenger()" style="z-index: 100;"> + </button>
                        </div>
                    </div>
                </li>
            </ul>
        </ng-template>
    </div>
</div>