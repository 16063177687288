import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Trip } from '../shared/models/trip';
import { Response } from '../shared/models/response';
import { Offers } from '../shared/models/offers';
import { ResponseOffer } from '../shared/models/response-offer';
import { Countries } from '../shared/models/countries';
import { Airport } from '../shared/models/airport';
import { Passenger } from '../shared/models/passenger';
import { BasicResponse } from '../shared/models/basic-request';

@Injectable({
  providedIn: 'root'
})
export class TripService {

  constructor(private http: HttpClient,
    @Inject('API_URL') private apiUrl: string,) { }

  private _controller = 'api/Trips';

  getTrips(airport: Airport): Observable<Response<Trip>> {
    return this.http.get<Response<Trip>>(`${this.apiUrl}${this._controller}/GetTrips?from=${airport.id}`);
  }

  getOffersByTripId(id: string): Observable<ResponseOffer<Offers>> {
    return this.http.get<ResponseOffer<Offers>>(`${this.apiUrl}${this._controller}/GetOffersByTripId/${id}`);
  }

  getOffersWithPaymentIntentByTripId(id: string, totalPassengers: number): Observable<ResponseOffer<Offers>> {
    const url = totalPassengers > 1 ? `${this.apiUrl}${this._controller}/GetOffersWithPaymentIntentByTripId/${id}?adults_qty=${totalPassengers}` : `${this.apiUrl}${this._controller}/GetOffersWithPaymentIntentByTripId/${id}`
    return this.http.get<ResponseOffer<Offers>>(url);
  }

  getNationalities(): Observable<Response<Countries>> {
    return this.http.get<Response<Countries>>(`${this.apiUrl}api/Countries/GetCountries`);
  }

  getAirports(): Observable<Response<Airport>> {
    return this.http.get<Response<Airport>>(`${this.apiUrl}api/Airport/GetAirports`);
  }

  validatePhoneNumber(passenger: BasicResponse<Passenger>): Observable<any> {
    return this.http.post(`${this.apiUrl}validate`, passenger);
  }
}
