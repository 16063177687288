<div class="body-cont">
    <div class="ticket-system">
        <div class="top">
            <div class="printer">
            </div>
            <div class="receipts-wrapper">
                <div class="receipts">
                    <div class="receipt">
                        <img class="airliner-logo" src="assets/img/zt-01.svg" alt="">
                        <div class="route">
                            <h3>Booking confirmed </h3>
                            <h3>Thank you!</h3>
                        </div>
                        <div class="details">
                            <div class="item">
                                <div class="item">
                                    <span>Booking No.</span>
                                    <h6 class="highlight-text">{{data.purchaseId}}</h6>
                                </div>
                                <span>Passangers</span>
                                <ng-container *ngFor="let passenger of data.passengers;">
                                    <h6>{{passenger.given_name}} {{passenger.family_name}}</h6>
                                </ng-container>
                            </div>

                            <div class="item">
                                <span>Departure</span>
                                <h6>{{data.trip.start_date | date: 'medium'}}</h6>
                            </div>
                            <div class="item">
                                <span>Ticket Information</span>
                                <h6>E-ticket and confirmation will be sent as separate emails to the following email
                                    address:</h6>
                                <h6 class="highlight-text my-2">{{data.passengers[0].email}}</h6>
                            </div>
                            <div class="item mt-2">
                                <h6>If you don't receive the confirmation and e-ticket within 24 hours, please contact
                                    Zumo Travel.</h6>
                            </div>
                        </div>
                    </div>
                    <div class="receipt footer">
                        <h5>Grand total:</h5>
                        <div class="description">
                            <h5>${{data.amount}} USD</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a (click)="close()">Go to the home page</a>
</div>