<div class="modal-background">
    <div class="container-pop-up">
        <button class="btn-close-icon" (click)="$event.stopPropagation();redirect()" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
        <img class="logo" src="assets/img/zt-01.svg" alt="">
        <h2 class="mt-4">GET 5% OFF</h2>
        <p>Sign up now for an exclusive welcome discount, new product updates, insider sales, expert tips and tricks,
            and
            more!</p>
        <mat-form-field floatLabel="always" appearance="outline">

            <input placeholder="Enter email here" [formControl]="subscribeForm" matInput>
            <mat-error
                *ngIf="subscribeForm?.errors && subscribeForm.errors?.['required']">Field
                is
                required</mat-error>
        </mat-form-field>
        <p class="privaci-policy gray-text">This discount applies to orders of $45 or more. By entering your email you
            agree to receive
            marketing communications from Zumo Travel at the email address provided. View our <a
                href="https://s3.amazonaws.com/zumo.travel/privacy-policy.pdf" target="_blank">Privacy Policy</a>.
        </p>
        <button [disabled]="subscribeForm.invalid" class="book-btn my-2 text-center" (click)="redirect()" mat-fab
            extended>
            GET 5% OFF
        </button>
    </div>
</div>