<div class="container">
    <img class="error-img mb-1" src="assets/img/error.png" alt="">
    <h2>Oh no!</h2>
    <h2 class="mb-4">{{title}}</h2>
    <div class="payment-message-error" *ngIf="isPaymentError">
        <h5 class="mb-2">We're sorry, but there was an issue issuing your plane tickets. <br>
            Your payment was successful, but we couldn't complete the ticketing process at this time. <br>
            Our team is already working on resolving this issue and will contact you proactively as soon as possible.
            <br><br>
            If you have any questions or need immediate assistance, please don't hesitate to contact Eduardo at
            <a href="mailto: eduardo@zumo.travel">eduardo@zumo.travel</a>.
        </h5>
        <div class="ref-container" *ngIf="purchaseId">
            <h5>Reference: </h5>
            <h5 class="orange-color ml-2">{{purchaseId}}</h5>
        </div>
    </div>
    <button [mat-dialog-close] class="book-btn my-4 text-center" (click)="btnBack()" mat-fab extended>
        {{btnText}}
    </button>
</div>