<div *ngFor="let slice of slices" class="row fly-border">
    <div class="col-sm-12 col-md-3 center-elements">
        <div class="departure-info">
            <span class="origin-destination">{{slice.origin.city_name}} -
                {{slice.destination.city_name}}</span>
            <span class="fly-time">{{slice.segments[0].departing_at | date: 'MMM d, y'}}</span>
        </div>
    </div>
    <div class="col-sm-12 col-md-3 center-elements">
        <div class="fly-time-info">
            <span class="fly-time-bold">{{slice.segments[0].departing_at | date: 'h:mm a'}} -
                {{slice.segments[0].arriving_at | date: 'h:mm a'}}</span>
            <div class="airlines mt-2">
                <img class="avatar-fly" [src]="slice.segments[0].operating_carrier.logo_symbol_url" alt="carrier">
                <span class="detail-airline ml-2 ">{{slice.segments[0].operating_carrier.name}}</span>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-3 stops center-elements">
        <div *ngIf="slice.stop_segments.length > 0" class="stops-information">
            <span>STOPS: {{slice.stop_segments.length}}</span>
            <ng-container *ngFor="let stop of slice.stop_segments">
                <p class="fly-time">{{stop.origin.iata_code}}</p>
            </ng-container>

        </div>
        <span *ngIf="slice.stop_segments.length === 0">DIRECT</span>
    </div>
    <div class="col-sm-12 col-md-3 center-elements">
        <div class="hours-city-info">
          <span class="origin-destination">{{getDuration(slice.duration)}}</span>
            <span>{{slice.origin.iata_code}} -
                {{slice.destination.iata_code}}</span>
        </div>
    </div>
</div>
