import { Component, Input, OnInit } from '@angular/core';
import { Offer } from '../../models/offer';
import { Slice } from '../../models/slice';
import { SpinnerDialogService } from 'src/app/services/spinner-dialog.service';
import { Duration } from 'luxon';

@Component({
  selector: 'app-fly-information-card',
  templateUrl: './fly-information-card.component.html',
  styleUrls: ['./fly-information-card.component.scss']
})
export class FlyInformationCardComponent {
  @Input() slices!: Slice[];
  @Input() offer!: Offer;

  getDuration(isoDuration: string): string {
    // Parse the ISO duration string into a Luxon Duration object
    const duration = Duration.fromISO(isoDuration);

    // Format the duration as needed
    let formatted = [];
    if (duration.hours > 0) {
      formatted.push(`${duration.hours}h`);
    }
    if (duration.minutes > 0) {
      formatted.push(`${duration.minutes}m`);
    }

    return formatted.join(' ');
  }
}
