<!--begin header -->
<header class="header">

  <!--begin navbar-fixed-top -->
  <nav class="navbar navbar-expand-lg navbar-default navbar-fixed-top opaque">

    <!--begin container -->
    <div class="container">

      <!--begin logo -->
      <a class="navbar-brand" href="#" alt="Zumo Travel" style="width: 30%">
        <img src="../../../../../../assets/img/landing/zumo-travel-logo.png" style="width: 100%"/>
      </a>
      <!--end logo -->

      <!--begin navbar-toggler -->
      <button (click)="toggleCollapsedMenu()" class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
      </button>
      <!--end navbar-toggler -->

      <!--begin navbar-collapse -->
      <div class="navbar-collapse collapse" [class.show]="showMenu" id="navbarCollapse" style="">

        <!--begin navbar-nav -->
        <ul class="navbar-nav ml-auto">

          <li><a (click)="navigateTo('#home')">Home</a></li>

          <li><a (click)="navigateTo('#about')">About</a></li>

          <li><a (click)="navigateTo('#why-us')">Why Us?</a></li>

          <li><a (click)="navigateTo('#faq')">FAQ</a></li>

          <li><a (click)="navigateTo('#contact')">Contact</a></li>

          <li class="discover-link"><a (click)="navigateTo('#home')" class="discover-btn">Free Plan!</a></li>

        </ul>
        <!--end navbar-nav -->

      </div>
      <!--end navbar-collapse -->

    </div>
    <!--end container -->

  </nav>
  <!--end navbar-fixed-top -->

</header>
<!--end header -->

<!--begin home section -->
<section class="home-section" id="home">

  <div class="home-section-overlay"></div>

  <!--begin container -->
  <div class="container">

    <!--begin row -->
    <div class="row">

      <!--begin col-md-6-->
      <div class="col-md-6 padding-top-120">

        <h1>Tired of Looking for Cheap Flights?</h1>

        <p class="hero-text">Stop wasting hours searching for good plane tickets, Zumo Travel has the best packages. Subscribe for the product launch and get a free intercontinental travel plan!</p>

        <p class="hero-text">Curated flights, a modern travel agency.</p>

      </div>
      <!--end col-md-6-->

      <!--begin col-md-5-->
      <div class="col-md-5 offset-md-1 margin-top-20">

        <!--begin register-form-wrapper-->
        <div class="register-form-wrapper wow bounceIn" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: bounceIn;">

          <h3>Get your free trip schedule!</h3>

          <p>Subscribe to our product launch and get a list with dates, flights and hotels to explore</p>

          <!--begin form-->
          <div>

            <!--begin success message -->
            <p *ngIf="itinerary_success" class="register_success_box">You'll receive your itinerary soon!</p>
            <!--end success message -->

            <!--begin register form -->
            <form *ngIf="!itinerary_success" [formGroup]="tripForm" id="register-form" class="register-form register" (ngSubmit)="onItinerarySubmit()">
              <input class="register-input name-input white-input" formControlName="itinerary_name" required="" [(ngModel)]="itinerary_name" name="name" placeholder="Your Name" type="text">
              <input class="register-input name-email white-input" formControlName="itinerary_email" required="" [(ngModel)]="itinerary_email" name="email" placeholder="Your Email" type="email">
              <select class="register-input white-input" formControlName="itinerary_trip" required="" [(ngModel)]="itinerary_trip" name="trip">
                <option value="">I want to travel to...</option>
                <option value="italy">Italy</option>
                <option value="europe">Spain, France & Amsterdam</option>
                <option value="south-korea">South Korea</option>
              </select>
              <input value="Get my free travel plan!" [disabled]="!tripForm.valid || itinerary_is_submitting" class="register-submit" type="submit">
            </form>
            <!--end register form -->

            <p class="register-form-terms">No Credit Card &#8226; No Installation Required</p>

          </div>
          <!--end form-->

        </div>
        <!--end register-form-wrapper-->

      </div>
      <!--end col-md-5-->

    </div>
    <!--end row -->

  </div>
  <!--end container -->

</section>
<!--end home section -->

<!--begin partners-section-->
<div class="partners-section">

  <!--begin container-->
  <div class="container">

    <!--begin row-->
    <div class="row">

      <!--begin col-sm-12-->
      <div class="col-md-12 mx-auto text-center partners">
        <img src="../../../../../../assets/img/landing/airlines/airfrance.png" class="partners" alt="image" style="max-height: 15px;">
        <img src="../../../../../../assets/img/landing/airlines/britishairways.png" class="partners" alt="image">
        <img src="../../../../../../assets/img/landing/airlines/americanairlines.png" class="partners" alt="image">
        <img src="../../../../../../assets/img/landing/airlines/lufthansa.png" class="partners" alt="image">
        <img src="../../../../../../assets/img/landing/airlines/vueling.png" class="partners" alt="image">
        <img src="../../../../../../assets/img/landing/airlines/easyjet.png" class="partners" alt="image">
      </div>
      <!--end col-sm-12-->

    </div>
    <!--end row-->

  </div>
  <!--end container-->

</div>
<!--end partners-section-->

<!--begin section-grey -->
<section class="section-grey" id="about">

  <!--begin container -->
  <div class="container">

    <!--begin row -->
    <div class="row">

      <!--begin col-md-12 -->
      <div class="col-md-12 text-center margin-bottom-10">

        <h2 class="section-title">What We Do</h2>

        <p class="section-subtitle">Stop wasting time searching for flights, we package the best</p>

      </div>
      <!--end col-md-12 -->

    </div>
    <!--end row -->

  </div>
  <!--end container -->

  <!--begin services-wrapper -->
  <div class="services-wrapper">

    <!--begin container -->
    <div class="container">

      <!--begin row -->
      <div class="row">

        <!--begin col-md-4 -->
        <div class="col-md-4">

          <div class="main-services blue">

            <i class="fa fa-route"></i>

            <h4>Best Routes</h4>

            <p>We find the best travel routes, with the cheapest prices </p>

          </div>

        </div>
        <!--end col-md-4 -->

        <!--begin col-md-4 -->
        <div class="col-md-4">

          <div class="main-services red">

            <i class="fa fa-globe-americas"></i>

            <h4>Many Countries</h4>

            <p>Multiple tickets, multiple destinations, everything at 1 click</p>

          </div>

        </div>
        <!--end col-md-4 -->

        <!--begin col-md-4 -->
        <div class="col-md-4">

          <div class="main-services green">

            <i class="fas fa-yin-yang"></i>

            <h4>Stress Free</h4>

            <p>We have a travel record of hundreds of hours, we'll choose best for you</p>

          </div>

        </div>
        <!--end col-md-4 -->

      </div>
      <!--end row -->

    </div>
    <!--end container -->

  </div>
  <!--end services-wrapper -->

</section>
<!--end section-grey -->

<!--begin section-white -->
<section class="section-white" id="why-us">

  <!--begin container-->
  <div class="container">

    <!--begin row-->
    <div class="row align-items-center">

      <!--begin col-md-6-->
      <div class="col-md-6 wow slideInLeft" data-wow-delay="0.25s" style="visibility: visible; animation-delay: 0.25s; animation-name: slideInLeft;">

        <div class="margin-right-15">

          <img src="../../../../../../assets/img/landing/explore1.jpg" class="width-100 image-shadow bottom-margins-images" alt="pic">

        </div>

      </div>
      <!--end col-sm-6-->

      <!--begin col-md-6-->
      <div class="col-md-6">

        <h3>Explore the world with flight routes chosen by experts</h3>

        <p>Turn your vacations into a stress-free adventure! Our experts choose every destination so you can forget about what flights to catch and focus on your exploring</p>

        <ul class="benefits">
          <li><i class="fas fa-check"></i> Enough time to explore the city between flights</li>
          <li><i class="fas fa-check"></i> No tickets in the middle of the night</li>
          <li><i class="fas fa-check"></i> With time to rest between cities</li>
        </ul>

        <a href="#" class="btn-red small scrool">Get a free travel plan!</a>

      </div>
      <!--end col-md-6-->

    </div>
    <!--end row-->

  </div>
  <!--end container-->

</section>
<!--end section-white-->

<!--begin section-grey -->
<section class="section-grey">

  <!--begin container-->
  <div class="container">

    <!--begin row-->
    <div class="row align-items-center">

      <!--begin col-md-6-->
      <div class="col-md-6">

        <div class="nav flex-column nav-pills margin-right-25" id="v-pills-tab" role="tablist" aria-orientation="vertical">

          <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">

            <!--begin features-second -->
            <div class="features-second">

              <div class="dropcaps-circle blue">
                <i class="far fa-clock"></i>
              </div>

              <h4 class="margin-bottom-5">Save Time</h4>

              <p>Save 10+ hours with Zumo's curated flights. Use that time to explore instead of worrying!</p>

            </div>
            <!--end features-second-->
          </a>

          <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">

            <!--begin features-second-->
            <div class="features-second">

              <div class="dropcaps-circle red">
                <i class="fa fa-clipboard-check"></i>
              </div>

              <h4 class="margin-bottom-5">We Walked The Walk</h4>

              <p>Every route has been tried by our team to ensure a fruitful experience</p>

            </div>
            <!--end features-second-->

          </a>

          <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">

            <!--begin features-second-->
            <div class="features-second">

              <div class="dropcaps-circle green">
                <i class="far fa-smile-beam"></i>
              </div>

              <h4 class="margin-bottom-5">Easy</h4>

              <p>Normally buying plane tickets take tens of submit forms. With Zumo Travel you only need to have a thirst for adventure!</p>

            </div>
            <!--end features-second-->

          </a>

        </div>

      </div>
      <!--end col-md-6-->

      <!--begin col-md-6 -->
      <div class="col-md-6">

        <div class="tab-content margin-left-20" id="v-pills-tabContent">

          <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
            <img src="../../../../../../assets/img/landing/explore2.jpg" class="width-100 image-shadow top-margins-images" alt="pic">
          </div>

          <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
            <img src="../../../../../../assets/img/landing/explore2.jpg" class="width-100 image-shadow top-margins-images" alt="pic">
          </div>

          <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
            <img src="../../../../../../assets/img/landing/explore2.jpg" class="width-100 image-shadow top-margins-images" alt="pic">
          </div>

        </div>

      </div>
      <!--end col-md-6 -->

    </div>
    <!--end row-->

  </div>
  <!--end container-->

</section>
<!--end section-grey-->

<!--begin section-bg-1 -->
<section class="section-bg-1">

  <div class="section-bg-overlay"></div>

  <!--begin container-->
  <div class="container">

    <!--begin row-->
    <div class="row">

      <!--begin col md 7 -->
      <div class="col-md-7 mx-auto margin-bottom-20 text-center">

        <h2 class="white-text">Fun Facts About Our Agency</h2>

      </div>
      <!--end col md 7-->

    </div>
    <!--end row-->

    <!--begin row-->
    <div class="row">

      <!--begin fun-facts-box -->
      <div class="col-md-2 offset-md-2 fun-facts-box text-center">

        <i class="fa fa-hiking"></i>

        <p class="fun-facts-title"><span class="facts-numbers">40+</span><br>Countries Explored</p>

      </div>
      <!--end fun-facts-box -->

      <!--begin fun-facts-box -->
      <div class="col-md-2 fun-facts-box text-center">

        <i class="fa fa-globe-africa"></i>

        <p class="fun-facts-title"><span class="facts-numbers">4</span><br>Continents</p>

      </div>
      <!--end fun-facts-box -->

      <!--begin fun-facts-box -->
      <div class="col-md-2 fun-facts-box text-center">

        <i class="fa fa-plane-departure"></i>

        <p class="fun-facts-title"><span class="facts-numbers">2000+</span><br>Flight Hours</p>

      </div>
      <!--end fun-facts-box -->

      <!--begin fun-facts-box -->
      <div class="col-md-2 fun-facts-box text-center">

        <i class="fas fa-coffee"></i>

        <p class="fun-facts-title"><span class="facts-numbers">100+</span><br>Baguettes eaten</p>

      </div>
      <!--end fun-facts-box -->

    </div>
    <!--end row-->

  </div>
  <!--end container-->

</section>
<!--end section-bg-1 -->

<!--begin faq section -->
<section class="section-white" id="faq">

  <!--begin container -->
  <div class="container">

    <!--begin row -->
    <div class="row">

      <!--begin col-md-12-->
      <div class="col-md-12 text-center padding-bottom-10">

        <h2 class="section-title">Frequently Asked Questions</h2>

        <p class="section-subtitle">Buying plane tickets has never so easy!</p>

      </div>
      <!--end col-md-12 -->

    </div>
    <!--end row -->

    <!--begin row -->
    <div class="row">

      <!--begin col-md-6-->
      <div class="col-md-6">

        <div class="faq-box">

          <h5>What destinations do you offer?</h5>

          <p>We focus on Europe and Sout Korea departing from North America, offering the intercontinental flight packaged with other shorter destinations. Crossing an ocean is far enough to visit only one country, so we have the best packaged routes at the best price</p>

        </div>

        <div class="faq-box">

          <h5>How do I book a trip with your agency?</h5>

          <p>Choose a trip, fill in your passenger and card details and you’ll have booked a flight! We are travelers like you and we understand buying a plane ticket nowadays is a total crusade, planning your trip should not be stressful so we make it easy for you!</p>

        </div>

      </div>
      <!--end col-md-6 -->

      <!--begin col-md-6-->
      <div class="col-md-6">

        <div class="faq-box">

          <h5>What is included in the travel packages?</h5>

          <p>Zumo Travel makes the flight purchase a walk in the park. Once you have completed your purchase and received your booking reference from the airline, you'll have plane tickets to visit your favourite destinations</p>

        </div>

        <div class="faq-box">

          <h5>Are there any hidden fees in your travel packages?</h5>

          <p>Our goal is to get you the best deals possible without having to worry about it. We will present the routes and prices clearly and transparent before any charge is made</p>

        </div>

      </div>

      <!--end col-md-6 -->

    </div>
    <!--end row -->

  </div>
  <!--end container -->

</section>
<!--end faq section -->

<!--begin section-bg-2 -->
<section class="section-bg-2" id="contact">

  <div class="section-bg-overlay"></div>

  <!--begin container-->
  <div class="container">

    <!--begin row -->
    <div class="row">

      <!--begin col-md-12-->
      <div class="col-md-12 text-center padding-bottom-10">

        <h2 class="section-title white-text">Contact Us</h2>

        <p class="section-subtitle white">Let us know where you want to travel!</p>

      </div>
      <!--end col-md-12 -->

    </div>
    <!--end row -->

    <!--begin row-->
    <div class="row justify-content-md-center">

      <!--begin col-md-8-->
      <div class="col-md-8 text-center margin-top-10">

        <!--begin contact-form-wrapper-->
        <div class="contact-form-wrapper wow bounceIn" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: bounceIn;">

          <!--begin form-->
          <div>

            <!--begin success message -->
            <p *ngIf="contact_success" class="contact_success_box">We received your message and you'll hear from us soon. Thank You!</p>
            <!--end success message -->

            <!--begin contact form -->
            <form *ngIf="!contact_success" [formGroup]="contactForm" (ngSubmit)="onContactSubmit()" id="contact-form" class="row contact-form contact">

              <!--begin col-md-6-->
              <div class="col-md-6">

                <input class="contact-input" required="" [(ngModel)]="contact_name" formControlName="contact_name" name="contact_name" placeholder="Your Name*" type="text">

                <input class="contact-input" required="" [(ngModel)]="contact_airport" formControlName="contact_airport" name="contact_airport" placeholder="Your Closest Airport*" type="text">

              </div>
              <!--end col-md-6-->

              <!--begin col-md-6-->
              <div class="col-md-6">

                <input class="contact-input" required="" [(ngModel)]="contact_email" formControlName="contact_email" name="contact_email" placeholder="Email Adress*" type="email">

                <select class="contact-input" required="" [(ngModel)]="contact_trip" formControlName="contact_trip" name="contact_trip">

                  <option value="">I want to travel to...</option>

                  <option value="Western Europe">Western Europe</option>

                  <option value="Easter Europe">Easter Europe</option>

                  <option value="Asia">Asia</option>

                </select>

              </div>
              <!--end col-md-6-->

              <!--begin col-md-12-->
              <div class="col-md-12">

                <textarea class="contact-input" rows="2" cols="20" [(ngModel)]="contact_message" formControlName="contact_message" name="contact_message" placeholder="Your Message..."></textarea>

                <input value="Get In Touch" [disabled]="!contactForm.valid" class="contact-submit" type="submit">

              </div>
              <!--end col-md-12-->

            </form>
            <!--end contact form -->

          </div>
          <!--end form-->

        </div>
        <!--end contact-form-wrapper-->

      </div>
      <!--end col-md-8-->

    </div>
    <!--end row-->

  </div>
  <!--end container-->

</section>
<!--end section-bg-2 -->
<app-footer></app-footer>
