import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeModule } from '@modules/home/home.module';
import { LandingModule } from '@modules/landing/landing.module';
import { HttpClientModule } from "@angular/common/http";
import { DetailsPageModule } from '@modules/details-page/details-page.module';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { MetaPixelService } from './services/meta-pixel.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NgxGoogleAnalyticsModule.forRoot('G-5V5W1XTV8Z'),
    NgxGoogleAnalyticsRouterModule,
    //PixelModule.forRoot({ enabled: true, pixelId: ["1191852825129004"] }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HomeModule,
    DetailsPageModule,
    LandingModule,
    // MatMomentDateModule
  ],
  providers: [MetaPixelService],
  bootstrap: [AppComponent]
})
export class AppModule { }
