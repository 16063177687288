import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PersonalDetailsFormStateService } from 'src/app/services/personal-details-form-state.service';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent {
  constructor(private _personalDetailStateService: PersonalDetailsFormStateService) {

  }
  @Input() control = new UntypedFormControl('');
  @Output() countryCodeChange = new EventEmitter<string>();
  selectedCountryConfig = {
    hideCode: true,
    hideName: true
  };
  countryListConfig = {
    hideCode: true
  };

  onCountryChange(country: any) {
    this.countryCodeChange.emit(country.dialling_code);
    // this._personalDetailStateService.dialCode.patchValue(country.dialling_code);
  }
}
