import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, takeUntil } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TripService } from 'src/app/services/trip.service';
import { Trip } from 'src/app/shared/models/trip';
import { Airport } from 'src/app/shared/models/airport';
import { TripDialogComponent } from '../trip-dialog/trip-dialog.component';
import { SpinnerDialogService } from 'src/app/services/spinner-dialog.service';
import { MetaPixelService } from 'src/app/services/meta-pixel.service';
import { PopUpSignUpService } from 'src/app/services/pop-up-sign-up.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  trips!: Trip[];
  airports!: Airport[];
  gridColumns = 3;
  protected _onDestroy$ = new Subject<void>();

  constructor(public service: TripService,
    private _spinner: SpinnerDialogService,
    private _metaPixelService: MetaPixelService,
    private _popUp: PopUpSignUpService
  ) { }

  ngOnInit(): void {
    this._metaPixelService.track("visitHome")
    this._spinner.startSpinner();
    this.__subscribeToAirports().subscribe((airports) => {
      this.airports = airports;
      this.__subscribeToTrips(airports);
    });
  }

  onDropdownValueChange(value: string) {
    this._spinner.startSpinner();
    const selected = this.airports.find(airport => airport.id === value) || this.airports[0];
    this._metaPixelService.track("search")
    this.__subscribeToTrips([selected]);
  }

  private __subscribeToAirports(): Observable<Airport[]> {
    return this.service.getAirports()
      .pipe(
        takeUntil(this._onDestroy$),
        map(response => response.results || [])
      );
  }

  private __subscribeToTrips(airports: Airport[]): void {
    this.service.getTrips(airports[0])
      .pipe(
        takeUntil(this._onDestroy$),
        map(response => response.results || [])
      )
      .subscribe(trips => {
        this.trips = trips;
        this._spinner.closeSpinner();
        setTimeout(() => {
          this._popUp.showPopUp();
        }, 5000);
      }, error => {
        console.error(error);
        this._spinner.closeSpinner();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
