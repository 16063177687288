import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { HomeRoutingModule } from './home-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './_components/home/home.component';
import { DropdownComponent } from './_components/dropdown/dropdown.component';
import { TripCardComponent } from './_components/trip-card/trip-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { TripDialogComponent } from './_components/trip-dialog/trip-dialog.component';
import { SharedModule } from "../../shared/shared.module";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    HomeComponent,
    TripCardComponent,
    TripDialogComponent,
    DropdownComponent
  ],
  exports: [
    HomeComponent,
    TripCardComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatCardModule,
    FlexLayoutModule,
    MatGridListModule,
    MatDialogModule,
    SharedModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class HomeModule { }
