import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';


@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss']
})
export class CustomDatepickerComponent {
  @Input() control = new UntypedFormControl('');
  @Input() labelText!: string
  @Input() minDate!: Date;
  @Input() maxDate!: Date;
}
