import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Offers } from '../../models/offers';
import { SpinnerDialogService } from 'src/app/services/spinner-dialog.service';
import { WINDOWS_ROOT_OBJECT } from 'src/app/const/const';
import { Router } from '@angular/router';
import { Trip } from '../../models/trip';
import { Slice } from '../../models/slice';
import { Luggage } from '../../models/luggage';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-fly-information',
  templateUrl: './fly-information.component.html',
  styleUrls: ['./fly-information.component.scss']
})
export class FlyInformationComponent {
  @Input() slices!: Slice[];
  @Input() trips!: Offers[];
  @Input() trip!: Trip;
  @Input() extra_carry_on_luggage_included!: Luggage[];
  @Input() amount!: number;
  @Output() closeModal = new EventEmitter<void>();
  @Input() isFromDetails = false;
  @Output() applyCoupon = new EventEmitter<string>();

  coupon = new UntypedFormControl();

  constructor(private _spinner: SpinnerDialogService,
    @Inject(WINDOWS_ROOT_OBJECT) private window: Window,
    public router: Router) {
  }

  apply() {
    if (this.coupon.value) {
      this.applyCoupon.emit(this.coupon.value)
    }

  }

  close() {
    // this.dialogRef.close();
    this.closeModal.emit();
  }

  get coverUrl(): string {
    return this.trip.coverUrl;
  }

  get isLoading() {
    return this._spinner.isLoading;
  }

  get tripName() {
    return this.trip.tripName;
  }
}
