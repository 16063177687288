<footer>
  <div class="container">
    <div class="row">
      <div class="col-12 footer-title">
        <p #p1>What is Zumo Travel?</p>
      </div>
      <div class="col-12 footer-subtitle">
        <p>Travel your way with our flight packages, discovering multiple cities in 1 click!</p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 footer-logos center">
        <p>Secure Payments By</p>
        <img src="assets/img/footer/stripe.png" style="width: 100px;" />
      </div>
      <div class="col-sm-12 col-md-4 footer-logos center">
        <p style="margin-right: 20px;">We accept</p>
        <img src="assets/img/footer/amex.png" style="width: 20px;" />
        <img src="assets/img/footer/visa.png" style="width: 50px;" />
        <img src="assets/img/footer/mc.png" style="width: 50px;" />
      </div>
      <div class="col-sm-12 col-md-4 footer-socials center">
        <p>Follow us</p>
        <a href="https://www.instagram.com/zumo.travel" target="_blank">
          <img src="assets/img/footer/instagram.png" />
        </a>
      </div>
    </div>
    <div class="center footer-bottom">
      <p>© 2024 Zumo Travel OÜ</p>
      <a href="https://s3.amazonaws.com/zumo.travel/terms-and-conditions.html.pdf" target="_blank">Terms &
        Conditions</a>
      <a href="https://s3.amazonaws.com/zumo.travel/privacy-policy.pdf" target="_blank">Privacy Policy</a>
    </div>
  </div>
</footer>