import { Injectable, Inject } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MetaPixelService } from 'src/app/services/meta-pixel.service';
import { GoogleAnalyticsService, GaActionEnum } from 'ngx-google-analytics';

@Component({
  selector: 'app-redirect',
  template: '<p>Loading...</p>'
})
export class PromoComponent {
  constructor(
    private router: Router,
    private _metaPixelService: MetaPixelService,
    protected $gaService: GoogleAnalyticsService,
    @Inject('ANALYTICS_ENABLED') private _analyticsEnabled: boolean
  ) {
    this._metaPixelService.track("visitPromo")
    window.location.href = 'https://ji1rcnw94s0.typeform.com/to/M9fvleF8#destination=xxxxx'; // External host
  }

  ngOnInit() {
    if(this._analyticsEnabled) {
      this.$gaService.event(GaActionEnum.VIEW_PROMOTION, undefined, undefined, undefined, undefined, {promotion_name: "pre_launch_survey", creative_slot: "sticker", creative_name: "15_discount_sticker", items: [{item_id: "launch_survey_sticker", item_name: "launch_survey_sticker"}]});
      this.$gaService.pageView('/promo', 'Promo Launch')
    }
  }
}
