<mat-card class="example-card" (click)="openModal(trip.id)">
    <img class="img" mat-card-image src="{{trip.coverUrl}}" alt="">
    <mat-card-content class="card-content">
        <div class="row">
            <div class="col-6 text-left">
                <h4 class="font-weight">{{trip.tripName}}</h4>
                <h6>{{trip.start_date | date: 'MMM dd' }} - {{trip.end_date | date: 'MMM dd' }}</h6>
            </div>
            <div class="col-6 text-right">
                <h6>{{trip.totalNights}} Nights</h6>
                <h4 class="font-weight">${{trip.approximatePrice}} USD</h4>
            </div>
        </div>
    </mat-card-content>
</mat-card>