<div class="header row">
    <div class="col-6 px-4 py-2 container-logo">
        <img class="logo" src="assets/img/zt-01.svg" alt="">
    </div>
    <div class="col-6 slogan">
        <div>
            <h1 id="slogan1">Find and book</h1>
            <h1 id="slogan2">your next adventure!</h1>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="location-dropdown">
        <h3>From</h3>
        <app-home-dropdown [airports]="airports" (valueChanged)="onDropdownValueChange($event)">
        </app-home-dropdown>
        <h3 class="to">To:</h3>
    </div>
    <div class="grid-container responsive-grid">
        <app-trip-card class="card" [trip]="trip" *ngFor="let trip of trips"></app-trip-card>
    </div>
</div>
<app-footer></app-footer>