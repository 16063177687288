import { InjectionToken } from "@angular/core";

export const getLocationObject = () => location;
export const getWindowObject = () => window;
export enum CodeStatus {
  OK = 'OK',
  FAILED = 'FAILED'
}
export const LOCATION = new InjectionToken<Location>('LOCATION', {
  providedIn: 'root',
  factory: getLocationObject
});

export const WINDOWS_ROOT_OBJECT = new InjectionToken<Window>('WINDOWS_ROOT_OBJECT', {
  providedIn: 'root',
  factory: getWindowObject
});

export const DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};