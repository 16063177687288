import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: string): string {
    var dateFormat = value.replace("PT", '');
    var characters = dateFormat.split("H");
    var formatting = `${characters[0]}h ${characters[1].toLowerCase()}`
    return formatting;
  }

}
