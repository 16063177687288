import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopUpSignUpComponent } from '../shared/components/pop-up-sign-up/pop-up-sign-up.component';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopUpSignUpService {
  popUp!: MatDialogRef<PopUpSignUpComponent>;
  constructor(
    private matDialog: MatDialog,
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string
  ) { }

  showPopUp() {
    this.hidePopUp();
    this.popUp = this.matDialog.open(PopUpSignUpComponent, {
      disableClose: true,
      autoFocus: false
    });
  }

  hidePopUp() {
    if (this.popUp) {
      this.popUp.close();
    }
  }

  subscribe(data: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}api/subscribe`, { email: data }).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}
