<div *ngIf="!isLoading" class="p-4 m-0 row">
    <div class="col-sm-12 col-md-10">
        <app-fly-information [slices]="slices" [trips]="trips" [trip]="data" [amount]="amount" [extra_carry_on_luggage_included]="extra_carry_on_luggage_included"
            (closeModal)="close()"></app-fly-information>
    </div>
    <div class="text-center col-sm-12 col-md-2 vertical-align last-column">
        <div>
            <h2 class="special-price-title">SPECIAL PRICE!!</h2>
        </div>
        <div>
            <h2>${{amount | number : '1.2-2'}} USD</h2>
        </div>
        <div>
            <h5 class="text-orange">Offer expires in:</h5>
            <h5 class="text-orange">{{ getExpirationDate() }}</h5>
        </div>
        <div><button class="book-btn my-2 text-center" (click)="bookNow()" mat-fab extended color="primary">
                Book Now
            </button></div>
    </div>
</div>
