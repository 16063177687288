import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MetaPixelService } from 'src/app/services/meta-pixel.service';

@Component({
  selector: 'app-redirect',
  template: '<p>Loading...</p>'
})
export class PromoComponent {
  constructor(private router: Router, private _metaPixelService: MetaPixelService) {
    this._metaPixelService.track("visitPromo")
    window.location.href = 'https://ji1rcnw94s0.typeform.com/to/M9fvleF8#destination=xxxxx'; // External host
  }
}
