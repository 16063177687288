import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { OrderResponse } from '../shared/models/order-response';
import { Order } from '../shared/models/order';
import { ConfirmPaymentIntentRequest } from '../shared/models/confirm-payment-intent-request';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient,
    @Inject('API_URL') private apiUrl: string,) { }

  private _controller = 'api/Payment';

  confirmPaymentIntent(data: ConfirmPaymentIntentRequest): Observable<OrderResponse<Order>> {
    return this.http.post<OrderResponse<Order>>(`${this.apiUrl}${this._controller}/ConfirmPaymentIntent`, data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}
