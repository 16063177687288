import { Injectable, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare var fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class MetaPixelService {

  constructor(
    @Inject('META_PIXEL_ENABLED') private _metaPixelEnabled: boolean
    ) {
  }

  track(event: string): void {
    let eventname: string | null = null;

    switch (event) {
      case 'visitPromo':
        eventname = 'StartRegistration';
        break;
      case 'visitHome':
        eventname = 'ViewContent';
        break;
      case 'visitDetailsPage':
        eventname = 'ViewContent';
        break;
      case 'clickBookNow':
        eventname = 'InitiateCheckout';
        break;
      case 'startFillingForm':
        eventname = 'StartRegistration';
        break;
      case 'clicksNext':
        eventname = 'CompleteRegistration';
        break;
      case 'purchaseComplete':
        eventname = 'Purchase';
        break;

      default:
        eventname = null;
    }

    if (eventname && this._metaPixelEnabled) {
      this._track(eventname);
    }
  }

  private _track(event: string, params: object = {}): void {
    if (typeof fbq !== 'undefined') {
      fbq('track', event, params);
    } else {
      console.warn('Meta Pixel is not initialized');
    }
  }

}
