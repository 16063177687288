<div class="row">
    <div *ngIf="!isFromDetails" class="col-sm-12 col-md-3 vertical-align">
        <button class="btn-close-icon" (click)="$event.stopPropagation();close()" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
        <h2 class="trip-name">{{tripName.toUpperCase()}}</h2>
        <h5>{{trip.start_date | date: 'MMM d, y'}} To {{trip.end_date | date: 'MMM d, y'}}</h5>
        <h4 class="text-orange">FROM</h4>
        <div class="visiting-container">
            <img class="img-cities" src="assets/img/favicons/favicon-32x32.png" alt="">
            <h5>{{ slices[0].origin.city_name}}</h5>
        </div>
        <h4 class="text-orange">VISITING</h4>
        <ng-container *ngFor="let city of trip.visiting;">
            <div class="visiting-container">
                <img class="img-cities" src="assets/img/favicons/favicon-32x32.png" alt="">
                <h5>{{city}}</h5>
            </div>

        </ng-container>
    </div>
    <div *ngIf="isFromDetails" [ngClass]="{'inline-block': isFromDetails}" class="col-12 vertical-align">
        <h2 class="trip-name">{{tripName.toUpperCase()}}</h2>
        <strong class="amount-header mt-2">Total Price: ${{amount | number : '1.2-2'}}</strong>
        <div [ngClass]="{'margin-top-1': isFromDetails}" class="row">
            <div class="col-4">
                <h4 class="text-orange">FROM</h4>
                <div class="visiting-container">
                    <img class="img-cities" src="assets/img/favicons/favicon-32x32.png" alt="">
                    <h5>{{ slices[0].origin.city_name }}</h5>
                </div>
            </div>
            <div class="col-4">
                <h4 class="text-orange">VISITING</h4>
                <ng-container *ngFor="let city of trip.visiting;">
                    <div class="visiting-container">
                        <img class="img-cities" src="assets/img/favicons/favicon-32x32.png" alt="">
                        <h5>{{city}}</h5>
                    </div>

                </ng-container>
            </div>
            <div class="col-4 vertical-align dates-info">
                <h5 [ngClass]="{'orange-color' : isFromDetails}">{{trip.start_date | date: 'MMM d, y'}} To
                    {{trip.end_date | date: 'MMM d, y'}}</h5>
            </div>
        </div>
    </div>
    <div [ngClass]="{'inline-block': isFromDetails, 'margin-top-1' : isFromDetails}" class="col-sm-12 col-md-9">
        <div class="row second-row">
            <div class="col-sm-12 col-md-6">
                <h5>TRIP DURATION: {{trip.totalNights}} DAYS</h5>
                <p>{{trip.description}}</p>
            </div>
            <div class="col-sm-12 col-md-6 text-center trip-info-icons">
                <div class="row nights-info">
                    <div class="col-md-6">
                        <div>
                            <strong>BAGS INCLUDED</strong>
                            <div>
                                <img class="icon mr-2" src="../../../../assets/img/luggage-02.png">
                                <span class="mr-2">0</span>
                                <img class="icon mr-2" src="../../../../../assets/img/luggage-01.png">
                                <span>1</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <strong>CABIN CLASS</strong>
                        <h6>ECONOMY</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-fly mt-2">
            <app-fly-information-card [slices]="slices"></app-fly-information-card>
        </div>
    </div>
</div>