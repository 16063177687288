import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuccessPaymentInformation } from 'src/app/shared/models/success-payment-information';

@Component({
  selector: 'app-success-payment-dialog',
  templateUrl: './success-payment-dialog.component.html',
  styleUrls: ['./success-payment-dialog.component.scss']
})
export class SuccessPaymentDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<SuccessPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SuccessPaymentInformation
  ) { }

  close() {
    this.dialogRef.close();
  }
}
