import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./scss/all.min.scss', '/scss/animate.scss', './scss/magnific-popup.scss', './scss/style.scss']
})
export class LandingComponent {
  tripForm: FormGroup;
  contactForm: FormGroup;
  itinerary_name: string = '';
  itinerary_email: string = '';
  itinerary_trip: string = '';
  itinerary_success: boolean = false;
  itinerary_is_submitting: boolean = false;
  contact_name: string = '';
  contact_airport: string = '';
  contact_email: string = '';
  contact_trip: string = '';
  contact_message: string = '';
  contact_is_submitting: boolean = false;
  contact_success: boolean = false;
  http_headers: HttpHeaders;
  showMenu: boolean = false;

  constructor(
    private http: HttpClient,
    private tripFormBuilder: FormBuilder,
    private contactFormBuilder: FormBuilder,
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.tripForm = this.tripFormBuilder.group({
      itinerary_name: ['', Validators.required],
      itinerary_email: ['', [Validators.required, Validators.email]],
      itinerary_trip: ['', Validators.required]
    });

    this.contactForm = this.contactFormBuilder.group({
      contact_name: ['', Validators.required],
      contact_airport: ['', Validators.required],
      contact_email: ['', [Validators.required, Validators.email]],
      contact_trip: ['', Validators.required],
      contact_message: ['', Validators.required],
    });

    this.http_headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Local
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0'
      // Prod
      //'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJ0anNvcm9uemRvZHloYmRlY2NsIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTkzOTgwMDQsImV4cCI6MjAxNDk3NDAwNH0.cLiQFCHg9_c1MTtiQFMx6e8sq650Klm-PZjczT9ySZ0'
    });
  }

  navigateTo(section: string) {
    this.showMenu = false;
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: section,
    });
  }

  toggleCollapsedMenu() {
    this.showMenu = !this.showMenu;
  }

  onContactSubmit() {
    if (this.contactForm.valid) {
      this.contact_is_submitting = true;

      const formData = {
        name: this.contact_name,
        airport: this.contact_airport,
        email: this.contact_email,
        trip: this.contact_trip,
        message: this.contact_message,
      };

      this.http.post('http://127.0.0.1:54321/functions/v1/contact-form', formData, { headers: this.http_headers }).subscribe(
        (response) => {
          console.log('Form submitted successfully', response);
          this.contact_success = true;
          // Handle successful response here
        },
        (error) => {
          console.error('Error submitting form', error);
          // Handle error here
        }
      );
    }
  }

  onItinerarySubmit() {
    if (this.tripForm.valid) {
      this.itinerary_is_submitting = true;

      const formData = {
        name: this.itinerary_name,
        email: this.itinerary_email,
        trip: this.itinerary_trip
      };

      this.http.post('https://btjsoronzdodyhbdeccl.supabase.co/functions/v1/free-travel-plan', formData, { headers: this.http_headers }).subscribe(
        (response) => {
          console.log('Form submitted successfully', response);
          this.itinerary_success = true;
          // Handle successful response here
        },
        (error) => {
          console.error('Error submitting form', error);
          // Handle error here
        }
      );
    }
  }
}
