import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ErrorPageComponent>,
    private _router: Router
  ) { }

  btnBack() {
    if (this.data.isPaymentError) {
      this._router.navigate(['/home']);
    }
  }

  get isPaymentError() {
    return this.data?.isPaymentError ?? false;
  }

  get title() {
    return this.data?.title;
  }

  get purchaseId() {
    return this.data?.purchaseId;
  }

  get message() {
    return this.data?.message;
  }

  get btnText() {
    return this.data.isPaymentError ? 'GO TO HOME PAGE' : 'TRY AGAIN';
  }
}
